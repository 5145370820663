import gsap from 'gsap';

export const colaborate = () => {
  gsap.set('.collaborate_inner', { y: '-100%' });

  gsap
    .timeline({
      defaults: {
        ease: 'none',
        duration: 1,
      },
      scrollTrigger: {
        trigger: '.collaborate',
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true,
      },
    })

    .to('.collaborate_inner', { y: 0 }, 0)

    .fromTo('.collaborate_button', { y: '200%' }, { y: 0 }, 0)
    .from('.collaborate_text', { y: '-5rem', opacity: 0, duration: 0.5 }, 0.5)
    .to('.infos_list, .photo_block_stars', { opacity: 0, duration: 0.3 }, 0.5)
    .from('.collaborate_helper', { opacity: 0, duration: 0.3 }, 0.7)
};
