import { onReady } from '@xatom/core';
import Lenis from '@studio-freight/lenis';
import gsap, { ScrollTrigger } from 'gsap/all';
import { cursor } from './components/cursor';
import { animSplit, setupSplit } from './components/split';
import { Routes } from './routes';
import { anim, setupAnim } from './components/anim';
import { loader } from './components/loader';
import { intro } from './components/intro';
import lenis from './components/lenis';
import { isMobile } from './components/isMobile';

gsap.registerPlugin(ScrollTrigger);

onReady(() => {
  if (!isMobile()) {
    cursor();
  }

  loader();

  Routes();

  // gsap.set('.w-webflow-badge', {
  //   filter: 'opacity(0)',
  // });

  setTimeout(() => {
    lenis.scrollTo(0, {
      immediate: true,
      onComplete: function () {
        lenis.stop();
      },
    });
  }, 100);

  const texts: HTMLElement[] = Array.from(
    document.querySelectorAll<HTMLElement>('[data-split]')
  );

  texts.forEach((text) => {
    const splitText = setupSplit(text);

    if (
      Array.from(text.classList).some((className) =>
        className.includes('navbar_')
      )
    ) {
      setTimeout(() => {
        animSplit(splitText, text);
      }, 2000);
    } else {
      setTimeout(() => {
        animSplit(splitText, text);
      }, 1000);
    }
  });

  setTimeout(() => {
    intro();
    lenis.start();
  }, 800);

  setupAnim();

  setTimeout(() => {
    anim();
  }, 2000);

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
});
