export const isMobile = () => {
  const userAgent = navigator.userAgent;
  const mobileKeywords = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone'
  ];

  const isMobileDevice = mobileKeywords.some(keyword => userAgent.includes(keyword));
  const isSmallScreen = window.innerWidth < 992;

  return isMobileDevice || isSmallScreen;
};
