import gsap from 'gsap';
import lenis from './lenis';
import { isMobile } from './isMobile';

export const footer = () => {
  const tl = gsap
    .timeline({
      defaults: {
        duration: 1,
      },
      scrollTrigger: {
        trigger: '.footer',
        start: '-=25% top',
        toggleActions: 'play pause resume reverse',
      },
    })

    tl.from('.footer_follow, .footer_me', { opacity: 0, duration: 1 })
    tl.from('.footer_me', { x: '-75%', duration: 1 }, '<50%')

    tl.from(
      '.footer_social_link',
      {
        display: 'inline-block',
        y: '100%',
        opacity: 0,
        stagger: 0.1,
      },
      '<25%'
    )

    tl.from('.footer_text', { y: '100%', opacity: 0 }, '<50%')
    tl.from('.footer_email', { y: '50%', opacity: 0 }, '<')

    if(isMobile) {
      tl.from('.footer_back', { x: '-100%', opacity: 0 }, '<25%');
    } else {
      tl.from('.footer_back', { y: '100%', opacity: 0 }, '<25%');
    }

  gsap
    .timeline({
      defaults: {
        duration: 1,
      },
      scrollTrigger: {
        trigger: '.footer',
        start: '-=25% center',
        end: '25% center',
        scrub: true,
      },
    })

    .to('.clients', { opacity: 0 }, 0);
  const button = document.querySelector('.footer_back');

  if (button) {
    gsap.set('.transition', { opacity: 0 });

    button.addEventListener('click', function () {
      gsap
        .timeline({
          onComplete: function () {
            lenis.scrollTo(0, {
               immediate: true,
            });
            gsap.to('.transition', { opacity: 0 });
          },
        })

        .fromTo('.transition', { opacity: 0  }, { opacity: 1 });
    });
  }
};
