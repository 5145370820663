import gsap from 'gsap';
import { isMobile } from './isMobile';

export const intro_photo = () => {
  if(!isMobile()) {
    gsap.timeline({
      defaults: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: '.intro_photo',
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,
        pin: '.intro_photo_inner',
      },
    });

    gsap
      .timeline({
        defaults: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: '.intro',
          start: 'top top',
          end: '+=100%',
          scrub: true,
        },
      })
      .to('.intro_photo_inner_img', { top: '2rem', width: '22.625rem' });

    const screenHeight = window.innerHeight;
    const topValue =
      screenHeight -
      32.75 * parseFloat(getComputedStyle(document.documentElement).fontSize);

    gsap
      .timeline({
        defaults: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: '.digital',
          start: 'top top',
          end: 'bottom bottom',
          scrub: true,
        },
      })
      .to('.intro_photo_inner_img', { top: topValue });
  }
};
