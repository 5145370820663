import gsap from 'gsap';
import lenis from './lenis';

export const contact = (): void => {
  const buttons: NodeListOf<Element> = document.querySelectorAll('.button');
  const contact: HTMLElement | null = document.querySelector('.contact');
  const contact_inner: HTMLElement | null =
    document.querySelector('.contact_inner');
  const close: HTMLElement | null = document.querySelector('.contact_close');

  //   if (contact) {
  //     const lenisContact = new Lenis({
  //       duration: 3,
  //       wrapper: contact,
  //       content: contact_inner,
  //     });

  //     gsap.ticker.add((time: number) => {
  //       lenisContact.raf(time * 1000);
  //     });
  //   }

  gsap.set(contact, { visibility: 'hidden' });

  let isContactOpen: boolean = false;

  const tl: gsap.core.Timeline = gsap.timeline({
    paused: true,
  });

  tl.to(contact, {
    visibility: 'visible',
    opacity: 1,
    pointerEvents: 'all',
    duration: 0,
  },);
  tl.from(contact, { y: '120%', duration: 1, ease: 'power3.out' });
  tl.fromTo('.main', { opacity: 1 }, { opacity: 0, duration: 1, ease: 'power3.out' }, '<');

  function contactOpen(): void {
    tl.restart();
    // lenisContact.start();
    lenis.stop();
    isContactOpen = true;
  }

  function contactClose(): void {
    tl.reverse();
    lenis.start();
    // lenisContact.stop();
    isContactOpen = false;
  }

  if (contact) {
    document.addEventListener('keydown', function (event: KeyboardEvent): void {
      if (event.key === 'Escape' && isContactOpen) {
        contactClose();
      }
    });
  }

  if (buttons) {
    buttons.forEach((button: Element) => {
      button.addEventListener('click', function (): void {
        contactOpen();
      });
    });
  }

  if (close) {
    close.addEventListener('click', function (): void {
      if (isContactOpen) {
        contactClose();
      }
    });
  }
};
