import { about_text } from '../components/aboutText';
import { colaborate } from '../components/colaborate';
import { intro_discover } from '../components/introDiscover';
import { intro_photo } from '../components/introPhoto';
import { list_photo } from '../components/listPhoto';
import { more } from '../components/more';
import { skills } from '../components/skills';
import { process } from '../components/process';
import { works } from '../components/works';
import { footer } from '../components/footer';
import { contact } from '../components/contact';
import { menu } from '../components/menu';
import { clients } from '../components/clients';

export const home = () => {
  menu();

  let body = document.querySelector('body');
  if (body.classList.contains('home')) {
    console.log("teste");
    intro_discover();
    intro_photo();
    about_text();
    list_photo();
    colaborate();
    skills();
    process();
    works();
    more();
    clients();
    footer();
    contact();
  }
};
