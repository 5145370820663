import gsap from 'gsap';

export const more = () => {
  gsap.set('.more_inner', { y: '-100%' });

  gsap
    .timeline({
      defaults: {
        ease: 'none',
        duration: 1,
      },
      scrollTrigger: {
        trigger: '.more',
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true,
      },
    })

    .to('.more_inner', { y: 0 }, 0)

    .fromTo('.more_button', { y: '150%' }, { y: 0 }, 0)
    .from('.more_title', { y: '-2rem', opacity: 0, duration: 0.5 }, 0.5)
    .from('.more_list', { y: '-2rem', opacity: 0, duration: 0.5 }, 0.5);
};
