import gsap, { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

export const setupAnim = () => {
  const animElements: HTMLElement[] = gsap.utils.toArray('[data-anim]');

  animElements.forEach((animElement) => {
    const att = animElement.getAttribute('data-anim');
    const innerContent = animElement.innerHTML;

    const innerDiv = document.createElement('div');
    innerDiv.classList.add('inner-div');
    innerDiv.innerHTML = innerContent;
    animElement.innerHTML = '';
    animElement.appendChild(innerDiv);

    if (att === 'small') {
      gsap.set(innerDiv, { y: '1.5rem', opacity: 0 });
    } else if (att === 'up') {
      gsap.set(innerDiv, { y: '-5rem', opacity: 0 });
    } else {
      gsap.set(innerDiv, { y: '5rem', opacity: 0 });
    }
  });
};

export const anim = () => {
  const anim: HTMLElement[] = gsap.utils.toArray('[data-anim]');

  anim.forEach((anim) => {
    const att = anim.getAttribute('data-anim');
    const innerDiv = anim.querySelector('.inner-div');

    const tl = gsap.timeline({
      paused: true,
      defaults: {
        duration: 2,
        ease: 'power3.out',
      },
      scrollTrigger: {
        trigger: anim,
        start: 'top bottom',
        toggleActions: 'play none none reset',
        // markers: true,
      },
    });

    if (att === 'small') {
      tl.fromTo(
        innerDiv,
        { y: '1.5rem', opacity: 0 },
        { y: '0rem', opacity: 1 },
        0
      );
    } else if (att === 'up') {
      tl.fromTo(
        innerDiv,
        { y: '-5rem', opacity: 0 },
        { y: '0rem', opacity: 1 },
        0
      );
    } else {
      tl.fromTo(
        innerDiv,
        { y: '5rem', opacity: 0 },
        { y: '0rem', opacity: 1 },
        0
      );
    }
  });
};
