import gsap from 'gsap';
import { isMobile } from './isMobile';

export const loader = () => {
  gsap.to('.preloader', { duration: 0.5, delay: 1, opacity: 0,
    onComplete: function () {
      document.querySelector('.preloader').remove();
    }
   })

  gsap.set('.intro_inner', { width: '73%' });
  gsap.set('.intro_photo_inner_img', {
    clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
  });
  if(!isMobile()) {
    gsap.set('.intro_photo_inner_img', { left: '8rem' });
  }
};
