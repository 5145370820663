import gsap from 'gsap';

export const process = () => {
  gsap
    .timeline({
      defaults: {
        ease: 'none',
        duration: 1,
      },
      scrollTrigger: {
        trigger: '.process',
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      },
    })
    .to('.skills', { y: '-25%' });

    document.querySelectorAll('[data-target]').forEach(function(element) {
      element.addEventListener('click', function() {
        var section = this.closest('[data-section]').getAttribute('data-section');
        var target = this.getAttribute('data-target');

        document.querySelectorAll('[data-section="'+section+'"] [data-target]').forEach(function(el) {
          el.classList.remove('active');
        });

        this.classList.add('active');

        document.querySelectorAll('[data-section="'+section+'"] [data-item]').forEach(function(el) {
          // Adicionando animação de fade out
          gsap.fromTo(el, { opacity: 1, y: 0 }, { opacity: 0, y: -20, duration: 0.5, onComplete: function() {
            let card =  document.querySelector('[data-section="'+section+'"] [data-item="'+target+'"]');

            // Após a animação de fade out, exibe o novo elemento com animação de fade in
            gsap.fromTo(card, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 0.5 });
            el.classList.remove('active');
            card.classList.add('active');
          } });
        });
      });
    });


  // gsap
  //   .timeline({
  //     scrollTrigger: {
  //       trigger: '.process',
  //       start: 'bottom bottom',
  //       end: '+=30%',
  //       scrub: true,
  //       pin: true,
  //     },
  //   })
  //   .from('.process_button', { y: '100%', opacity: 0 });
};
