import gsap from 'gsap';
import { isMobile } from './isMobile';

export const intro = () => {
  gsap.set('.intro_discover_icon', { scale: 0 });

  const tl = gsap.timeline()

  tl.from('.intro_discover', { scale: 0.5, duration: 1 })
  tl.from('.intro_discover_scroll', { scale: 2, duration: 1 }, '<')
  tl.to('.intro_discover_icon', { scale: 1 }, '<80%')
  tl.fromTo('.intro_inner', { width: '73%' }, { width: '100%' })
  tl.from('.navbar_menu_icon', { y: '120%' }, '<40%')
  // .from(".navbar", { opacity: 0 }, "<")
  if(!isMobile()) {
    tl.fromTo('.intro_photo_inner_img', { left: '8rem' }, { left: '0rem' }, '<')
  }
  tl.fromTo(
    '.intro_photo_inner_img',
    { clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)' },
    { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' },
    '<'
  )
  tl.fromTo('.jp_scene', { opacity: 0 }, { opacity: 0.5 });

  gsap.timeline({
    scrollTrigger: {
      trigger: ".jp_scene_wrapper",
      start: 'top top',
      end: 'bottom bottom',
      pin: isMobile() ? '.jp_video' : '.jp_scene',
      scrub: true,
    }
  })

  .fromTo('body', { backgroundColor: '#060606' }, { backgroundColor: '#000' })

  if(isMobile()) {
    gsap.timeline({
      scrollTrigger: {
        trigger: ".works",
        start: 'top top',
        end: '+=50%',
        scrub: true,
      }
    })

    .to('.jp_video', { opacity: 0 })
  }
};
