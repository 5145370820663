import gsap from 'gsap';

export const clients = () => {
  gsap.to(".clients_marquee_inner", { x: '-50%', repeat: -1, ease: 'none', duration: 60 })

  gsap.set('.clients_stories_content', { maxHeight: 0 });

  const items = document.querySelectorAll('.clients_stories_header');
  items.forEach((item) => item.setAttribute('aria-expanded', 'false'));

  function toggleAccordion(item) {
    const itemToggle = item.getAttribute('aria-expanded');

    for (i = 0; i < items.length; i++) {
      items[i].setAttribute('aria-expanded', 'false');

      const text = items[i].querySelector('.stories_read div');
      text.textContent = "Read";

      const icon = items[i].querySelector('.stories_read_icon');
      if (icon) {
        gsap.to(icon, { rotate: 0, ease: 'power3.out' });
      }

      const nextElement = items[i].nextElementSibling;
      const innerElement = nextElement.querySelector('.clients_stories_content_inner');

      if (nextElement) {
        gsap.to(nextElement, { duration: 1, maxHeight: 0, ease: 'power3.out' });
        gsap.to(innerElement, { opacity: 0, ease: 'power3.out',  duration: 0.5 });
      }
    }

    if (itemToggle == 'false') {
      item.setAttribute('aria-expanded', 'true');

      const text = item.querySelector('.stories_read div');
      text.textContent = "Close";

      const icon = item.querySelector('.stories_read_icon');
      if (icon) {
        gsap.to(icon, { rotate: -45, ease: 'power3.out' });
      }
      const nextElement = item.nextElementSibling;
      const innerElement = nextElement.querySelector('.clients_stories_content_inner');
      if (nextElement) {
        gsap.to(nextElement, { duration: 1, maxHeight: innerElement.clientHeight, ease: 'power3.out' });
        gsap.to(innerElement, { opacity: 1, ease: 'power3.out', duration: 1 });
      }
    }
  }

  items.forEach((item) => item.addEventListener('click', function() {
    toggleAccordion(this);
  }));
};
