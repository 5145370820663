import gsap from 'gsap';
import { isMobile } from './isMobile';

export const skills = () => {
  if(!isMobile()) {
    gsap.timeline({
      paused: true,
      defaults: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: '.skills',
        start: 'top top',
        end: '+=150%',
        pin: true,
        scrub: true,
      },
    })

    .from('.skills_title.alt', { y: '-90%' })
    .from('.skills_side_title', { y: '90%' }, '<')
    .from('.skills_items', { y: '140%' }, '<');
  }

  // Accordion
  gsap.set('.skills_item_content', { maxHeight: 0 });

  const items = document.querySelectorAll('.skills_item_title');
  items.forEach((item) => item.setAttribute('aria-expanded', 'false'));

  function toggleAccordion(item) {
    const itemToggle = item.getAttribute('aria-expanded');

    for (i = 0; i < items.length; i++) {
      items[i].setAttribute('aria-expanded', 'false');

      const icon = items[i].querySelector('.skills_item_icon');
      if (icon) {
        gsap.to(icon, { rotate: 0, ease: 'power3.out' });
      }

      const nextElement = items[i].nextElementSibling;
      if (nextElement) {
        gsap.to(nextElement, { maxHeight: 0, ease: 'power3.out' });
      }
    }

    if (itemToggle == 'false') {
      item.setAttribute('aria-expanded', 'true');

      const icon = item.querySelector('.skills_item_icon');
      if (icon) {
        gsap.to(icon, { rotate: -45, ease: 'power3.out' });
      }
      const nextElement = item.nextElementSibling;
      if (nextElement) {
        gsap.to(nextElement, { maxHeight: nextElement.scrollHeight, ease: 'power3.out' });
      }
    }
  }

  items.forEach((item) => item.addEventListener('click', function() {
    toggleAccordion(this);
  }));

  // Open last accordion
  const lastItem = items[items.length - 1];
  toggleAccordion(lastItem);
};
