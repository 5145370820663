import gsap from 'gsap';

export const list_photo = () => {
  gsap
    .timeline({
      defaults: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: '.photo',
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    })

    .to('.photo_block_img', { y: '-30%' });

  gsap.timeline({
    defaults: {
      duration: 1.5,
    },
    repeat: 20,
  })
  .to('.photo_block_star', { rotate: 45 })
  .to('.photo_block_star', { rotate: 90 });


  function move(list) {
    gsap.to(list, {
      duration: 1,
      y: "-25%",
      onEnter: function() {
        var firstItemClone = list.children[0].cloneNode(true);
        list.appendChild(firstItemClone);
        gsap.to(list.children[0], { opacity: 0 })
        gsap.to(list.children[1], { opacity: 0.5, color: '#d0cbc0' })
        gsap.to(list.children[2], { opacity: 1, color: "#fff" })
      },
      onComplete: function() {
        list.firstElementChild.remove();
        gsap.set(list, { y: 0 });
      }
    });
  }
  var list = document.querySelector('.infos_list_inner')
  setInterval(function() {
    move(list);
  }, 1500);

};
