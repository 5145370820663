import gsap from 'gsap';
import { isMobile } from './isMobile';

export const works = (): void => {
  const works: Element[] = gsap.utils.toArray('.works_item');
  const worksLength: number = works.length;

  const dots: Element[] = gsap.utils.toArray('.works_dot');

  gsap.to('.works_item_cat_inner', {
    duration: 10,
    x: '-50%',
    repeat: -1,
    ease: 'none',
  });

  gsap.set('.works_item', {
    position: 'absolute',
    left: 0,
    top: 0,
    visibility: 'hidden',
  });

  const tl = gsap.timeline({
    defaults: {
      duration: 1,
      ease: 'none',
    },
    scrollTrigger: {
      trigger: '.works',
      start: 'top top',
      end: `+=${worksLength + 3}00%`,
      scrub: true,
      pin: true,
      pinSpacing: true,
    },
  });

  const titleX = isMobile() ? '80vw' : '75vw';

  tl.fromTo('.works_title', { x: titleX }, { duration: 2, x: `-${titleX}` });
  tl.fromTo(
    '.works_intro_image',
    {
      clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
      x: '50%',
    },
    {
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      x: 0,
      duration: 0.25,
    },
    '<25%'
  );
  tl.from('.works_intro_text', { opacity: 0, y: '100%', duration: 0.25, }, '<');

  tl.to(
    '.works_intro_image',
    { clipPath: 'polygon(60% 0%, 60% 0%, 60% 100%, 60% 100%)', duration: 0.7 },
   '1');
  tl.to('.works_intro_text', { opacity: 0, y: '-100%', duration: 0.5 }, '<');

  works.forEach((work: Element, index: number) => {
    const workImg: Element = work.querySelector('.works_item_image')!;
    const workImgCover: Element = work.querySelector(
      '.works_item_image_cover'
    )!;
    const workTitle: Element = work.querySelector('.works_item_title')!;
    const workCat: Element = work.querySelector('.works_item_cat_inner')!;

    const dot = dots[index];

    if (index === 0) {
      tl.to(work, { opacity: 1, visibility: 'visible', duration: 0 }, '<');
      if(isMobile) {
        tl.fromTo(
          workImg,
          {
            clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
            x: '-20%',
          },
          { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', x: 0 }
        );
      } else {
        tl.fromTo(
          workImg,
          {
            clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
            x: '-20%',
          },
          { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', x: 0 },
          '<'
        );
      }
      tl.from('.works_dots', { opacity: 0, x: '-300%', duration: 0.5 }, '<50%');
      tl.from(workTitle, { opacity: 0, y: '50%', duration: 0.5 }, '<80%');
      tl.from(workCat, { y: '100%', duration: 0.5 }, '<');

      tl.to(workImg, {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        y: '-30%',
      });
      tl.to(workImgCover, { opacity: 0 }, '<');
      if(isMobile) {
        tl.to(workTitle, { opacity: 0, y: '10%', duration: 0.5 }, '<');
      } else {
        tl.to(workTitle, { opacity: 0, y: '100%', duration: 0.5 }, '<');
      }
      tl.to(workCat, { y: '100%', duration: 0.5 }, '<');

      tl.to(dot, { rotation: 180 }, '<');
    } else {
      tl.to(work, { visibility: 'visible' }, '<');
      tl.fromTo(
        workImg,
        { clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)', y: '130%' },
        { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', y: 0 },
        '<'
      );
      tl.from(workImgCover, { opacity: 0 }, '<');
      if(isMobile) {
        tl.from(workTitle, { opacity: 0, y: '10%', duration: 0.5 });
      } else {
        tl.from(workTitle, { opacity: 0, y: '100%', duration: 0.5 });
      }
      tl.from(workCat, { y: '100%', duration: 0.5 }, '<');

      if (index !== works.length - 1) {
        tl.to(workImg, {
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
          y: '-30%',
        });
        tl.to(workImgCover, { opacity: 0 }, '<');
        if(isMobile) {
          tl.to(workTitle, { opacity: 0, y: '10%', duration: 0.5 }, '<');
        } else {
          tl.to(workTitle, { opacity: 0, y: '100%', duration: 0.5 }, '<');
        }
        tl.to(workCat, { y: '100%', duration: 0.5 }, '<');

        const previousDot = dots[index - 1];
        tl.set(
          previousDot.firstElementChild,
          {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
          '<'
        );

        tl.set(
          dot.firstElementChild,
          {
            backgroundColor: 'white',
            borderColor: 'white',
          },
          '<'
        );

        tl.to(dot, { rotation: 180 }, '<');
      }
    }
  });
};
