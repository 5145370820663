import gsap from 'gsap';

export const intro_discover = () => {
  gsap.to('.intro_discover_scroll', {
    duration: 15,
    rotation: '-360deg',
    repeat: -1,
    ease: 'none',
  });

  // gsap
  //   .timeline({
  //     defaults: {
  //       ease: 'none',
  //     },
  //     scrollTrigger: {
  //       trigger: '.intro',
  //       start: 'top top',
  //       end: '+=50%',
  //       scrub: true,
  //     },
  //   })

  //   .fromTo('.intro_discover_icon', { scale: 1 }, { scale: 0 })
  //   .fromTo('.intro_discover', { scale: 0 }, { scale: 0.5 }, '<30%');
  // .fromTo('.intro_discover_scroll', { scale: 1 }, { scale: 2 }, '<');
};
