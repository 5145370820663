import gsap, { SplitText } from 'gsap/all';

export const about_text = () => {
  let about_text = new SplitText('.about_text', {
    type: 'lines',
  });

  gsap
    .timeline({
      defaults: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: '.about',
        start: 'top center',
        end: 'bottom bottom',
        scrub: true,
      },
    })
    .from(about_text.lines, { opacity: 0, stagger: 0.2 });

  gsap
    .timeline({
      defaults: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: '.about',
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      },
    })

    .to('.about_text', { y: '100vh' });
};
