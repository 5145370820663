import gsap, { ScrollTrigger, SplitText } from 'gsap/all';

gsap.registerPlugin(SplitText, ScrollTrigger);

export const setupSplit = (element) => {
  let splitType = element.getAttribute('data-split');

  let split = new SplitText(element, {
    type: 'chars, words',
    wordsClass: 'word',
  });

  let numChars = split.chars.length;

  for (let i = 0; i < numChars; i++) {
    if (splitType === 'up') {
      gsap.set(split.chars[i], {
        y: `${Math.random() * (251 - 100) + 100}%`,
      });
    } else if (splitType === 'line-up') {
      gsap.set(split.chars[i], {
        y: '100%',
      });
    } else {
      gsap.set(split.chars[i], {
        y: `-${Math.random() * (251 - 100) + 100}%`,
      });
    }
  }

  return split;
};

export const animSplit = (split, element) => {
  let tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 1.5,
      ease: 'power3.out',
    },
    scrollTrigger: {
      trigger: element,
      start: 'top bottom',
      toggleActions: 'play none none reset',
    },
  });

  let numChars = split.chars.length;

  for (let i = 0; i < numChars; i++) {
    tl.to(
      split.chars[i],
      {
        y: 0,
      },
      0
    );
  }

  return tl;
};
