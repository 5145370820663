import gsap from 'gsap';
import lenis from './lenis';
import { SplitText } from 'gsap/all';

gsap.registerPlugin(SplitText);

export const menu = () => {
  const menu = document.querySelector('.menu');
  const menu_trigger = document.querySelector('.navbar_menu');
  const menu_close = document.querySelector('.menu_close');
  const menu_links = document.querySelectorAll('.menu_list .menu_link');

  gsap.set(menu, { visibility: 'hidden', opacity: 0 });

  let split = new SplitText('.menu_list .menu_link', {
    type: 'chars, words',
    wordsClass: 'word',
  });

  let numChars = split.chars.length;

  for (let i = 0; i < numChars; i++) {
    gsap.set(split.chars[i], {
      y: `-${Math.random() * (251 - 100) + 100}%`,
    });
  }

  const tl = gsap.timeline({ paused: true });

  tl.set(menu, { visibility: 'visible', pointerEvents: 'all' });
  // tl.add(() => {
  //   document.body.classList.toggle('is-blur');
  // });
  tl.fromTo('.main, .navbar', { opacity: 1 }, { opacity: 0, duration: 1, ease: 'power3.out' });
  tl.to(menu, { opacity: 1, duration: 1, ease: 'power3.out' }, "<80%");
  for (let i = 0; i < numChars; i++) {
    tl.to(
      split.chars[i],
      {
        y: 0,
      }, '<'
    );
  }
  tl.from('.menu .button', { y: "100%", duration: 1, ease: 'power3.out' }, "<");
  tl.from(
    '.menu_social .menu_link',
    {
      display: 'inline-block',
      y: '100%',
      opacity: 0,
      stagger: 0.1,
    },
    '<'
  )

  let isMenuOpen: boolean = false;

  function menuOpen(): void {
    tl.play();
    lenis.stop();
    isMenuOpen = true;
  }

  function menuClose(): void {
    tl.reverse();
    lenis.start();
    isMenuOpen = false;
  }

  const texts: HTMLElement[] = Array.from(
    document.querySelectorAll<HTMLElement>('.menu_link')
  );

  if (menu) {
    document.addEventListener('keydown', function (event: KeyboardEvent): void {
      if (event.key === 'Escape' && isMenuOpen) {
        menuClose();
      }
    });
  }

  if (menu_trigger) {
    menu_trigger.addEventListener('click', function () {
      menuOpen()
    });
  }

  if (menu_close) {
    menu_close.addEventListener('click', function () {
      menuClose()
    });
  }

  if (menu_links) {
    menu_links.forEach(function(menu_link) {
      menu_link.addEventListener('click', function () {
        menuClose()
      });
    });
  }
};
