import gsap from 'gsap';

export const cursor = () => {
  const cursor = document.querySelector('.cursor');
  const cursorCircle = document.querySelector('.cursor_circle');
  const cursorDot = document.querySelector('.cursor_dot');

  gsap.set(cursor, { opacity: 0 });
  gsap.set(cursorCircle, { scale: 0 });
  gsap.set(cursorDot, { scale: 0 });

  let circleX = gsap.quickTo(cursorCircle, 'x', { duration: 0.2 });
  let circleY = gsap.quickTo(cursorCircle, 'y', { duration: 0.2 });

  let dotX = gsap.quickTo(cursorDot, 'x', { duration: 0.01 });
  let dotY = gsap.quickTo(cursorDot, 'y', { duration: 0.01 });

  window.addEventListener('mousemove', (e) => {
    const { x, y } = e;
    circleX(x);
    circleY(y);
    dotX(x);
    dotY(y);
  });

  document.documentElement.addEventListener('mouseleave', () => {
    gsap.to(cursor, { duration: 0.2, opacity: 0 });
    gsap.to(cursorCircle, { scale: 0 });
    gsap.to(cursorDot, { scale: 0 });
  });

  document.documentElement.addEventListener('mouseenter', () => {
    gsap.to(cursor, { duration: 0.2, opacity: 1 });
    gsap.to(cursorCircle, { scale: 1 });
    gsap.to(cursorDot, { scale: 1 });
  });

    const links = document.querySelectorAll('a, [data-cursor]');

  links.forEach((link) => {
    link.addEventListener('mouseenter', () => {
      gsap.to(cursorCircle, { scale: 1.13333 });
      gsap.to(cursorDot, { scale: 2.25 });
    });
    link.addEventListener('mouseleave', () => {
      gsap.to(cursorCircle, { scale: 1 });
      gsap.to(cursorDot, { scale: 1 });
    });
  });

  document.documentElement.classList.add('has_cursor');
};



// export const cursor = () => {
//   const cursor = document.querySelector('.cursor');
//   const cursorCircle = document.querySelector('.cursor_circle');
//   const cursorDot = document.querySelector('.cursor_dot');

//   gsap.set(cursor, { opacity: 0 });
//   gsap.set(cursorCircle, { scale: 0 });
//   gsap.set(cursorDot, { scale: 0 });

//   let circleX = gsap.quickTo(cursorCircle, 'x', { duration: 0.2 });
//   let circleY = gsap.quickTo(cursorCircle, 'y', { duration: 0.2 });

//   let dotX = gsap.quickTo(cursorDot, 'x', { duration: 0.01 });
//   let dotY = gsap.quickTo(cursorDot, 'y', { duration: 0.01 });

//   window.addEventListener('mousemove', (e) => {
//     const { clientX, clientY } = e;
//     const circleHalfWidth = cursorCircle.offsetWidth / 2;
//     const circleHalfHeight = cursorCircle.offsetHeight / 2;
//     const x = clientX - circleHalfWidth;
//     const y = clientY - circleHalfHeight;

//     circleX(x);
//     circleY(y);

//     dotX(x);
//     dotY(y);
//   });

//   document.documentElement.addEventListener('mouseleave', () => {
//     gsap.to(cursor, { duration: 0.2, opacity: 0 });
//     gsap.to(cursorCircle, { scale: 0 });
//     gsap.to(cursorDot, { scale: 0 });
//   });

//   document.documentElement.addEventListener('mouseenter', () => {
//     gsap.to(cursor, { duration: 0.2, opacity: 1 });
//     gsap.to(cursorCircle, { scale: 1 });
//     gsap.to(cursorDot, { scale: 1 });
//   });

//   const links = document.querySelectorAll('a');

//   links.forEach((link) => {
//     link.addEventListener('mouseenter', () => {
//       gsap.to(cursorDot, { scale: 2 });
//     });
//     link.addEventListener('mouseleave', () => {
//       gsap.to(cursorDot, { scale: 1 });
//     });
//   });

//   document.documentElement.classList.add('has_cursor');
// };